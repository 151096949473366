import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import React, { useState } from "react";

import BusinessRoundedIcon from "@mui/icons-material/BusinessRounded";
import DirectionsSubwayRoundedIcon from "@mui/icons-material/DirectionsSubwayRounded";
import DirectionsBusRoundedIcon from "@mui/icons-material/DirectionsBusRounded";
import AirportShuttleRoundedIcon from "@mui/icons-material/AirportShuttleRounded";

import { Map, MapMarker, CustomOverlayMap } from "react-kakao-maps-sdk";

const LocationComponent = ({ enlarged }) => {
  const [position, setPosition] = useState({
    lat: 37.48668132575886,
    lng: 127.12054341497917,
  });

  return (
    <div>
      <Grid container sx={{ padding: "2em 1em", backgroundColor: "#efefef" }}>
        {/*타이틀*/}
        <Grid item xs={12}>
          <Box sx={{ mb: "2em", textAlign: "center" }}>
            <Typography
              variant="h4"
              sx={{
                color: "#212121",
                fontFamily: "NotoSansKR-Bold",
                mb: "2em",
              }}
            >
              찾아 오시는 길
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
          <Box
            sx={{
              backgroundColor: "#616161",
              width: enlarged ? "80%" : "100%",
            }}
          >
            <Grid container spacing={2}>
              {/* 약도 */}
              <Grid item xs={12} md={7}>
                <Box sx={{ padding: enlarged ? "2em" : "1em" }}>
                  <Paper
                    variant="outlined"
                    sx={{
                      padding: "1em",
                      textAlign: "center",
                    }}
                  >
                    {/* <Box
                                            component="img"
                                            src="/images/contact/map.png"
                                            sx={{
                                                width: "80%",
                                                height: "auto",
                                            }}
                                        /> */}
                    <Box>
                      <Map
                        level={4}
                        center={position}
                        style={{
                          width: "100%",
                          height: "400px",
                        }}
                      >
                        <CustomOverlayMap position={position}>
                          <div
                            style={{
                              backgroundColor: "white",
                              borderRadius: "5px",
                              boxShadow: "0px 1px 2px #888",
                              transform: "translate(0px, -55px)",
                              padding: "2px",
                            }}
                          >
                            {/* 링크 */}
                            <a
                              href="https://map.kakao.com/link/map/907739512"
                              style={{
                                color: "black",
                                textDecoration: "none",
                              }}
                            >
                              <span
                                style={{
                                  padding: "2px",
                                  fontSize: "15px",
                                }}
                              >
                                문정역SKV1
                                {/* 밑줄 */}
                                <span
                                  style={{
                                    width: "90%",
                                    content: '""',
                                    position: "absolute",
                                    left: 4,
                                    bottom: "2px",
                                    borderBottom: "1px solid black",
                                  }}
                                />
                              </span>
                            </a>
                          </div>
                        </CustomOverlayMap>
                        <MapMarker position={position}></MapMarker>
                      </Map>
                    </Box>
                  </Paper>
                </Box>
              </Grid>
              {/* 약도 끝 */}
              {/* 주소 및 교통편 시작 */}
              <Grid item xs={12} md={5}>
                <Box>
                  <Box
                    sx={{
                      height: "400px",
                      paddingLeft: enlarged ? "2em" : "1em",
                    }}
                  >
                    {/* 주소 시작 */}
                    <Stack
                      direction="row"
                      sx={{
                        alignItems: "center",
                        mt: enlarged ? "50px" : 0,
                      }}
                    >
                      <BusinessRoundedIcon
                        fontSize="small"
                        sx={{
                          color: "white",
                          mr: "0.3em",
                        }}
                      />
                      <Typography
                        variant="body1"
                        sx={{
                          fontFamily: "NotoSansKR-Bold",
                          color: "white",
                        }}
                      >
                        주소
                      </Typography>
                    </Stack>
                    <Box
                      sx={{
                        paddingLeft: "1em",
                        mb: "1em",
                      }}
                    >
                      <Typography variant="body2" sx={{ color: "white" }}>
                        서울특별시 송파구 법원로 128 문정역 SKV1 B동 1103호 (우:05854)
                      </Typography>
                    </Box>
                    {/* 주소 끝 */}
                    {/* 지하철 시작 */}
                    <Stack direction="row" sx={{ alignItems: "center" }}>
                      <DirectionsSubwayRoundedIcon
                        fontSize="small"
                        sx={{
                          color: "white",
                          mr: "0.3em",
                        }}
                      />
                      <Typography
                        variant="body1"
                        sx={{
                          fontFamily: "NotoSansKR-Bold",
                          color: "white",
                        }}
                      >
                        지하철
                      </Typography>
                    </Stack>
                    <Box
                      sx={{
                        paddingLeft: "1em",
                        mb: "1em",
                      }}
                    >
                      <Typography variant="body2" sx={{ color: "white" }}>
                        서울 8호선 문정역 3번 또는 4번 출구
                      </Typography>
                    </Box>
                    {/* 지하철 끝 */}
                    {/* 간선버스 시작 */}
                    <Stack direction="row" sx={{ alignItems: "center" }}>
                      <DirectionsBusRoundedIcon
                        fontSize="small"
                        sx={{
                          color: "white",
                          mr: "0.3em",
                        }}
                      />
                      <Typography
                        variant="body1"
                        sx={{
                          fontFamily: "NotoSansKR-Bold",
                          color: "white",
                        }}
                      >
                        간선/지선/광역버스
                      </Typography>
                    </Stack>
                    <Box
                      sx={{
                        paddingLeft: "1em",
                        mb: "1em",
                      }}
                    >
                      <Typography variant="body2" sx={{ color: "white" }}>
                        문정로데오거리입구 정류장 <br />
                        [간선] 302,303,320,333,343,345,350,360,422 <br />
                        [지선] 3420,3422 <br />
                        [광역] 500-1,1009,1112,1117,1650,3302,G2100,M5342 <br />
                        [일반] 30,31,32,100,119,331
                      </Typography>
                    </Box>
                    {/* 간선버스 끝 */}
                    {/* 마을버스 시작 */}
                    <Stack direction="row" sx={{ alignItems: "center" }}>
                      <AirportShuttleRoundedIcon
                        fontSize="small"
                        sx={{
                          color: "white",
                          mr: "0.3em",
                        }}
                      />
                      <Typography
                        variant="body1"
                        sx={{
                          fontFamily: "NotoSansKR-Bold",
                          color: "white",
                        }}
                      >
                        마을버스
                      </Typography>
                    </Stack>
                    <Box
                      sx={{
                        paddingLeft: "1em",
                        mb: "1em",
                      }}
                    >
                      <Typography variant="body2" sx={{ color: "white" }}>
                        문정현대지식산업센터 정류장 <br />
                        송파02
                      </Typography>
                    </Box>
                    {/* 마을버스 끝 */}
                  </Box>
                </Box>
              </Grid>
              {/* 주소 및 교통편 끝 */}

              <Grid item xs={12} md={4}></Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default LocationComponent;
