import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";

const YearlyEvents = ({ items, spacingBetween }) => (
  <Box sx={{ position: "relative", paddingTop: "2em" }}>
    <Box>
      <Stack spacing={3} direction="row" sx={{ alignItems: "start" }}>
        <Stack spacing={2} direction="row" sx={{ alignItems: "center" }}>
          <Box component="img" src="/images/company/won.png" sx={{ width: "15px", height: "15px", zIndex: 1000 }} />
          <Typography variant="h6">{items.year}</Typography>
        </Stack>
        <Box>
          {items.events.map((item, idx) => (
            <Typography key={idx} variant="body2" sx={{ mb: spacingBetween }}>
              {item}
            </Typography>
          ))}
        </Box>
      </Stack>
    </Box>
    <Box
      sx={{
        position: "absolute",
        left: "7px",
        width: "1px",
        height: "100%",
        top: 0,
        backgroundColor: "#eee",
      }}
    />
  </Box>
);

const HistoryComponent = ({ containerHeight, enlarged }) => {
  return (
    <div>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Grid container sx={{ width: enlarged ? "80%" : "100%" }}>
          <Grid item xs={12} md={5} sx={{ padding: "2em" }}>
            <Box sx={{ position: "relative" }}>
              <Box
                component="img"
                src="/images/company/history.png"
                sx={{ width: "100%", height: "auto", objectFit: "cover" }}
              />
              <Box sx={{ position: "absolute", top: "10%", left: "3em" }}>
                <Typography
                  variant="h3"
                  sx={{
                    fontFamily: "NotoSansKR-Bold",
                    color: "white",
                    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.25), -2px -2px 4px rgba(0, 0, 0, 0.25)",
                  }}
                >
                  History
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={7}>
            <Box
              sx={{
                padding: "3em 2em",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <YearlyEvents
                items={{
                  year: "2024",
                  events: [
                    "11월, 현대제철 L2 정밀진단",
                    "7월, 행성사 차세대 MES 아키텍처 블루프린트 수립",
                    "2월, 하나은행 차세대 마케팅 플랫폼 구축 완료",
                    "1월, 농협생명ISP 완료",
                  ],
                }}
                spacingBetween="1em"
              />
              <YearlyEvents
                items={{
                  year: "2023",
                  events: [
                    "12월, 현대해상IT중장기전략수립 완료",
                    "8월, Azul Systems 공식 채널 계약",
                    "7월, 23년도 서울 4대 미래성장산업 유망선도기업 선정",
                    "3월, 한국해양진흥공사 차세대 시스템 구축 완료",
                  ],
                }}
                spacingBetween="1em"
              />
              <YearlyEvents
                items={{
                  year: "2022",
                  events: [
                    "11월, 전진영 공동대표 취임",
                    "11월, SK주식회사 협력사 등록",
                    "10월, 한국해양진흥공사 클라우드 인프라 구축 완료",
                  ],
                }}
                spacingBetween="1em"
              />
              <YearlyEvents
                items={{
                  year: "2021",
                  events: ["11월, Appspine 프레임워크 개발", "6월, 진달래소프트 설립 (대표이사:이용한)"],
                }}
                spacingBetween="1em"
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default HistoryComponent;
