import React, { useRef, useState } from "react";

import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Box,
  Button,
  Container,
  Drawer,
  IconButton,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import ArrowRightRoundedIcon from "@mui/icons-material/ArrowRightRounded";

import KeyboardVoiceRoundedIcon from "@mui/icons-material/KeyboardVoiceRounded";
import UpdateRoundedIcon from "@mui/icons-material/UpdateRounded";
import Diversity2RoundedIcon from "@mui/icons-material/Diversity2Rounded";
import SellRoundedIcon from "@mui/icons-material/SellRounded";

/*
import MenuBookIcon from "@mui/icons-material/MenuBook";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import ConstructionIcon from "@mui/icons-material/Construction";
import SaveAsIcon from "@mui/icons-material/SaveAs";
*/

import Diversity1RoundedIcon from "@mui/icons-material/Diversity1Rounded";
import AccountTreeRoundedIcon from "@mui/icons-material/AccountTreeRounded";
import SafetyDividerRoundedIcon from "@mui/icons-material/SafetyDividerRounded";

import PermContactCalendarRoundedIcon from "@mui/icons-material/PermContactCalendarRounded";
import WhereToVoteRoundedIcon from "@mui/icons-material/WhereToVoteRounded";

import CorporateFareRoundedIcon from "@mui/icons-material/CorporateFareRounded";
import SwitchAccountRoundedIcon from "@mui/icons-material/SwitchAccountRounded";
import ContactSupportRoundedIcon from "@mui/icons-material/ContactSupportRounded";

import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";

export const menus = [
  {
    id: "company",
    link: "/company#top",
    title: "회사소개",
    icon: <CorporateFareRoundedIcon />,
    items: [
      {
        title: "미션 스토리",
        link: "/company#mission",
        icon: <KeyboardVoiceRoundedIcon />,
      },
      {
        title: "지나온 길",
        link: "/company#history",
        icon: <UpdateRoundedIcon />,
      },
      {
        title: "멤버 소개",
        link: "/company#members",
        icon: <Diversity2RoundedIcon />,
      },
      {
        title: "CI",
        link: "/company#ci",
        icon: <SellRoundedIcon />,
      },
    ],
  },
  {
    id: "talented",
    link: "/talented#top",
    title: "채용안내",
    icon: <SwitchAccountRoundedIcon />,
    items: [
      {
        title: "복리후생",
        link: "/talented#benefits",
        icon: <Diversity1RoundedIcon />,
      },
      {
        title: "채용절차",
        link: "/talented#process",
        icon: <AccountTreeRoundedIcon />,
      },
      {
        title: "채용분야",
        link: "/talented#occu",
        icon: <SafetyDividerRoundedIcon />,
      },
    ],
  },
  {
    id: "contact",
    link: "/contact#top",
    title: "업무문의",
    icon: <ContactSupportRoundedIcon />,
    items: [
      {
        title: "연락처",
        link: "/contact#contact",
        icon: <PermContactCalendarRoundedIcon />,
      },
      {
        title: "찾아오시는 길",
        link: "/contact#map",
        icon: <WhereToVoteRoundedIcon />,
      },
    ],
  },
];

/*
export const menus = [
  {
    id: "company",
    link: "/company#top",
    title: "회사소개",
    icon: <CorporateFareRoundedIcon />,
    items: [
      {
        title: "미션 스토리",
        link: "/company#mission",
        icon: <KeyboardVoiceRoundedIcon />,
      },
      {
        title: "지나온 길",
        link: "/company#history",
        icon: <UpdateRoundedIcon />,
      },
      {
        title: "멤버 소개",
        link: "/company#members",
        icon: <Diversity2RoundedIcon />,
      },
      {
        title: "CI",
        link: "/company#ci",
        icon: <SellRoundedIcon />,
      },
    ],
  },
  {
    id: "business",
    link: "/business#top",
    title: "사업영역",
    icon: <WidgetsRoundedIcon />,
    items: [
      {
        title: "비즈니스 포트폴리오",
        link: "/business#portfolio",
        icon: <MenuBookIcon />,
      },
      {
        title: "IT 컨설팅",
        link: "/business#consulting",
        icon: <DesignServicesIcon />,
      },
      {
        title: "IT 시스템 구축",
        link: "/business#infra",
        icon: <ConstructionIcon />,
      },
      {
        title: "솔루션 공급",
        link: "/business#solution",
        icon: <SaveAsIcon />,
      },
    ],
  },
  {
    id: "talented",
    link: "/talented#top",
    title: "채용안내",
    icon: <SwitchAccountRoundedIcon />,
    items: [
      {
        title: "복리후생",
        link: "/talented#benefits",
        icon: <Diversity1RoundedIcon />,
      },
      {
        title: "채용절차",
        link: "/talented#process",
        icon: <AccountTreeRoundedIcon />,
      },
      {
        title: "채용분야",
        link: "/talented#occu",
        icon: <SafetyDividerRoundedIcon />,
      },
    ],
  },
  {
    id: "contact",
    link: "/contact#top",
    title: "업무문의",
    icon: <ContactSupportRoundedIcon />,
    items: [
      {
        title: "연락처",
        link: "/contact#contact",
        icon: <PermContactCalendarRoundedIcon />,
      },
      {
        title: "찾아오시는 길",
        link: "/contact#map",
        icon: <WhereToVoteRoundedIcon />,
      },
    ],
  },
];
*/

const MenuBar = ({ enlarged, scrolled }) => {
  const navigate = useNavigate();

  const companyEl = useRef();
  const businessEl = useRef();
  const talentedEl = useRef();
  const contactEl = useRef();

  const [whatToShowMenu, setWhatToShowMenu] = useState(null);
  const [whatToExpandMenu, setWhatToExpandMenu] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);

  const goto = (url) => {
    navigate(url);
    setWhatToShowMenu(null);
    setWhatToExpandMenu(null);
    setOpenDrawer(false);
  };

  const getRef = (id) => {
    let ref = null;

    switch (id) {
      case "company":
        ref = companyEl;
        break;
      case "business":
        ref = businessEl;
        break;
      case "talented":
        ref = talentedEl;
        break;
      case "contact":
        ref = contactEl;
        break;
      default:
    }

    return ref;
  };

  const handleMenuClose = () => {
    setWhatToShowMenu(null);
  };

  const handleAccordionChange = (id, link) => (event, isExpanded) => {
    setWhatToExpandMenu(isExpanded ? id : null);
    if (isExpanded) {
      navigate(link);
    }
  };

  const mainMenuStyle = {
    fontFamily: "GmarketSans-Medium",
    "&:hover": { color: "#f50057" },
  };

  return (
    <AppBar position="fixed" color={scrolled ? "inherit" : "transparent"} elevation={scrolled ? 3 : 0}>
      <Container maxWidth="lg">
        <Toolbar
          variant="dense"
          sx={{
            display: { xs: "none", md: scrolled ? "none" : "flex" },
          }}
        >
          {/* <Box gap={2} sx={{ display: "flex" }}>
            <Box
              component="img"
              src="/images/blog_w.svg"
              sx={{ width: 25, height: 25 }}
            />
            <Box
              component="img"
              src="/images/instagram_w.svg"
              sx={{ width: 25, height: 25 }}
            />
            <Box
              component="img"
              src="/images/wyoutube.svg"
              sx={{ width: 25, height: 25 }}
            />
          </Box> */}
        </Toolbar>
        <Toolbar>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
            }}
            gap={4}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Link to="/#top">
                <Box
                  component="img"
                  alt="진달래소프트"
                  src={scrolled ? "/images/logo.png" : "/images/wlogo.png"}
                  sx={{
                    height: 75,
                    width: 279,
                  }}
                />
              </Link>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }} gap={4}>
              {menus.map((item, idx) => (
                <div key={idx}>
                  <Button
                    onClick={() => goto(item.link)}
                    onMouseEnter={() => setWhatToShowMenu(item.id)}
                    ref={getRef(item.id)}
                    sx={{
                      maxHeight: "30px",
                      minHeight: "30px",
                    }}
                  >
                    <Typography
                      sx={{
                        ...mainMenuStyle,
                        color: scrolled ? "#212121" : "white",
                      }}
                      variant="h6"
                    >
                      {item.title}
                    </Typography>
                  </Button>
                  <Popper
                    open={enlarged && whatToShowMenu === item.id}
                    anchorEl={getRef(item.id).current}
                    role={undefined}
                    placement="bottom-start"
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin: placement === "bottom-start" ? "left top" : "left bottom",
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleMenuClose}>
                            <MenuList
                              autoFocusItem={enlarged && whatToShowMenu === item.id}
                              id={`composition-menu-${idx}`}
                              aria-labelledby={`composition-button-${idx}`}
                              onMouseLeave={handleMenuClose}
                              //onKeyDown={handleListKeyDown}
                            >
                              {item.items.map((item, idx) => (
                                <MenuItem key={idx} onClick={() => goto(item.link)}>
                                  {item.title}
                                </MenuItem>
                              ))}
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </div>
              ))}
            </Box>
          </Box>
          <Box
            sx={{
              display: {
                xs: "flex",
                md: "none",
                width: "100%",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexGrow: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Link to="/#top">
                <Box
                  component="img"
                  alt="진달래소프트"
                  src={scrolled ? "/images/logo.png" : "/images/wlogo.png"}
                  sx={{
                    height: 40,
                    width: 149,
                  }}
                />
              </Link>
            </Box>

            <Box sx={{ position: "absolute", top: 0, right: 0 }}>
              <IconButton size="large" onClick={() => setOpenDrawer(true)}>
                <MenuRoundedIcon
                  fontSize="large"
                  sx={{
                    color: scrolled ? "#212121" : "white",
                  }}
                />
              </IconButton>
            </Box>
          </Box>
        </Toolbar>
      </Container>
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        PaperProps={{
          sx: {
            backgroundColor: "#424242",
          },
        }}
      >
        <Box
          sx={{
            height: "50px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            bgcolor: "white",
          }}
        >
          <IconButton
            aria-label="close-menu"
            onClick={() => {
              goto("/#top");
            }}
            sx={{ ml: "1em" }}
          >
            <HomeRoundedIcon />
          </IconButton>

          <IconButton
            aria-label="close-menu"
            onClick={() => {
              setOpenDrawer(false);
            }}
            sx={{ mr: "1em" }}
          >
            <CloseRoundedIcon />
          </IconButton>
        </Box>
        <Box sx={{ width: "300px", backgroundColor: "#424242" }}>
          {menus.map((item, idx) => (
            <Accordion
              key={idx}
              elevation={0}
              expanded={whatToExpandMenu === item.id}
              onChange={handleAccordionChange(item.id, item.link)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreRoundedIcon sx={{ color: "white" }} />}
                sx={{ backgroundColor: "#424242" }}
              >
                <Typography
                  variant="body"
                  sx={{
                    fontFamily: "GmarketSans-Medium",
                    color: "white",
                  }}
                >
                  {item.title}
                </Typography>
              </AccordionSummary>
              {item.items.map((item, idx) => (
                <AccordionDetails
                  key={idx}
                  sx={{
                    backgroundColor: "#e0e0e0",
                    height: "10px",
                  }}
                >
                  <Stack direction="row">
                    <ArrowRightRoundedIcon />
                    <Typography variant="body2" sx={{ cursor: "pointer" }} onClick={() => goto(item.link)}>
                      {item.title}
                    </Typography>
                  </Stack>
                </AccordionDetails>
              ))}
            </Accordion>
          ))}
        </Box>
      </Drawer>
    </AppBar>
  );
};

export default MenuBar;
